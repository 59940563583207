import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Paper, Typography, Grid, Card } from "@material-ui/core";
import useStyles from "./styles";
import {
  firestore,
  FieldValue,
  functions,
  useFirebase,
} from "../../../components/FirebaseProvider";
import AppPageLoading from "../../../components/AppPageLoading";
import Logo from "../../../components/Logo";
import StoreIcon from "@material-ui/icons/Store";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import StudentsIcon from "@material-ui/icons/People";
import VideoIcon from "@material-ui/icons/Movie";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import VideoPlayer from "./VideoPlayer";
import Video from "./video";
import { useDocument } from "react-firebase-hooks/firestore";

function Produk(props) {
  const classes = useStyles();
  const { user } = useFirebase();

  return (
    <>
      
      <Grid>
        <h3>{props?.nama_produk}</h3>
          {props.isExpired ? (
          <Paper className={classes.paper}>
            <Typography className={classes.title}>
              Mohon Maaf Akses Nobar Anda Sudah Expired!
            </Typography>
          </Paper>
        ) : props.isLaunched ? (
          <Paper className={classes.paper}>
            {props.daftar_konten.map((konten, ind)=>{
              return <>
              <p>
                <Video videoid={konten.videoid} email={user?.email} title={konten.title ? konten.title : ""} id={props.id.toString()} uid={user?.uid} />
                </p>
              </>
            })}
            <p><b>Warning:</b><br />Setelah di klik, Anda memiliki Akses menonton selama 48 jam.<br />Pastikan Anda klik saat akan menonton saja !</p>
            <p>
              Video ini terlisensi untuk <b>{user?.email}</b>.
            </p>
            <p>
            {props?.startDate ? `Tanggal Mulai akses nobar : ${new Date(props?.startDate.seconds * 1000)}` : "" }<br />
            {props?.expiryDate? `Tanggal Akhir akses nobar : ${new Date(props?.expiryDate.seconds * 1000)}` : ""}
            </p>
            <p>
              Hak Cipta Milik PT.Teladan Kreator Indonesia, Dilarang Menyebarkan Konten
              Video Ini.<br />
              Segala pelanggaran akan dilaporkan pada pihak berwajib dan akan
              ditindak sesuai ketetuan Undang-undang yang berlaku.
            </p>
              
            
          </Paper>
        ) : (
          <Paper className={classes.paper}>
            <Typography className={classes.title}>
              Harap bersabar ya, Nobar belum dimulai :){" "}
            </Typography>
          </Paper>
        )}

      </Grid>
    </>
  );
}

export default Produk;
