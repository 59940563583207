import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  title: {
    textAlign: "center",
    marginBottom: theme.spacing(3),
  },
  paper: {
    marginTop: theme.spacing(8),
    padding: theme.spacing(6),
  },
  buttons: {
    marginTop: theme.spacing(6),
  },
  forgotPassword: {
    marginTop: theme.spacing(3),
  },
  pageTitle: {
    marginTop: 20,
    color: "grey",
    fontSize: 20,
    textAlign: "center",
    fontWeight: 600,
    marginBottom: 0,
  },

  pageSubtitle: {
    color: "grey",
    textAlign: "center",
    fontSize: 13,
    fontWeight: 400,
    marginTop: 0,
    marginBottom: 30,
  },
}));

export default useStyles;
