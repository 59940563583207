import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({

    loadingBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '50vh'
    }

}))


export default useStyles;