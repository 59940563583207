import React, { useEffect, useState } from "react";
import dataBank from './bank.json'
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { firestore, FieldValue } from "../../../components/FirebaseProvider";
import { useSnackbar } from "notistack";
import { useData } from '../../../components/DataProvider';

export default function AddBank({ open, handleClose }) {
    const { enqueueSnackbar } = useSnackbar();

    const { user_id } = useData();

    const [form, setForm] = useState({
        bank_tujuan: '',
        no_rek: '',
        nama_rek: ''
    });
    // console.log(totalOmset)


    const [error, setError] = useState({
        bank_tujuan: '',
        no_rek: '',
        nama_rek: ''
    });
    const handleChange = e => {
        let value = e.target.value
        if (e.target.type === 'number') {
            value = parseInt(value)
        }
        // console.log(e.target.type)
        setForm({
            ...form,
            [e.target.name]: value
        })

        setError({
            ...error,
            [e.target.name]: ''
        })
    }
    // console.log(form)

    // data Bank
    const [Bank, setBank] = useState([]);
    const [filter, setFilter] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {

        async function fetchBank() {
            setLoading(true);
            try {
                const getBank = dataBank.map((bank) => bank.name);

                const response = await getBank();

                setBank(response.data)
            } catch (e) {

            }
            setLoading(false);
        }

        fetchBank();
    }, [])

    const filteredBank = dataBank.filter(Bank => {

        return Bank.name.toLowerCase().includes(filter.toLowerCase());
    });

    const validate = async () => {
        const newError = { ...error };

        if (!form.bank_tujuan) {
            newError.bank_tujuan = 'Bank tujuan harus diisi';
        }



        if (!form.no_rek) {
            newError.no_rek = 'No rekening harus diisi';
        }
        if (!form.nama_rek) {
            newError.nama_rek = 'Nama rekening harus diisi';
        }




        return newError;
    }
    const handleSubmit = async e => {
        e.preventDefault();
        const findError = await validate();

        // console.log(findError)
        if (Object.values(findError).some(m => m !== "")) {
            setError(findError)
        } else {
            setLoading(true)
            try {
                const MitraBank = firestore.collection(`user_bank`)
                await MitraBank.add({
                    // ...form?.bank_tujuan,
                    no_rek: form?.no_rek,
                    // code: form?.bank_tujuan?.code,
                    code: form?.bank_tujuan?.code,
                    // id: addBank?.id,
                    name: form?.bank_tujuan?.name,
                    created_at: FieldValue.serverTimestamp(),
                    user_id: user_id,
                    nama_rek: form?.nama_rek
                })
                handleClose()
                enqueueSnackbar('Pencairan ditambahkan', { variant: "success" })

                // history.push(`product/edit/${form.product_id}`)

            } catch (e) {
                const newError = {};
                enqueueSnackbar('Pencairan gagal ditambahkan', { variant: "error" })

                newError.title = e.message;

                setError(newError);
            }

            setLoading(false)
        }
    }


    return <Dialog
        disableBackdropClick={loading}
        disableEscapeKeyDown={loading}
        open={open}
        onClose={handleClose}
    >
        <DialogTitle>Tambah Rek. Tujuan</DialogTitle>
        <DialogContent dividers>
            <Grid container spacing={2}>

                <Grid item xs={12}>
                    <Autocomplete
                        id="bank_tujuan"
                        name="bank_tujuan"
                        margin="normal"
                        required
                        value={form?.bank_tujuan || ''}
                        onChange={(event, value) => {
                            handleChange({ target: { name: "bank_tujuan", value } });
                        }}
                        // disabled={loadingStok || loadingVariants || cekLoad}
                        options={filteredBank || ''}
                        getOptionLabel={(option) => option?.name || ''}
                        renderInput={(params) => (
                            <TextField
                                helperText={error?.bank_tujuan}
                                error={error?.bank_tujuan ? true : false}
                                {...params}
                                label='Bank Tujuan'
                                variant="outlined"
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        autoComplete="off"
                        disabled={loading}
                        id="no_rek"
                        name="no_rek"
                        value={form?.no_rek}
                        label="No Rekening"
                        onChange={handleChange}
                        error={error.no_rek ? true : false}
                        helperText={error.no_rek}
                        fullWidth
                        multiline
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        autoComplete="off"
                        disabled={loading}
                        id="nama_rek"
                        name="nama_rek"
                        value={form?.nama_rek}
                        label="Atas Nama"
                        onChange={handleChange}
                        error={error.nama_rek ? true : false}
                        helperText={error.nama_rek}
                        fullWidth
                        multiline
                        variant="outlined"
                    />
                </Grid>

            </Grid>
        </DialogContent>
        <DialogActions>
            <Button
                disabled={loading}
                onClick={handleClose}
            >Batal</Button>
            <Button

                onClick={handleSubmit}
                disabled={loading}
                color="primary"
            >
                Buat
            </Button>
        </DialogActions>
    </Dialog>
}
AddBank.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired
}