import React, { useState } from "react";

import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import useStyles from "./styles";

import { Redirect } from "react-router-dom";
import isEmail from "validator/lib/isEmail";

import { auth, useFirebase } from "../../components/FirebaseProvider";

import AppLoading from "../../components/AppLoading";
import { Helmet } from "react-helmet";
import Logo from "../../components/Logo";

function Login(props) {
  const { location } = props;
  const classes = useStyles();

  const [form, setForm] = useState({
    email: "",
    password: "",
  });

  const [error, setError] = useState({
    email: "",
    password: "",
  });

  const [isSubmitting, setSubmitting] = useState(false);

  const { user, loading } = useFirebase();

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
    setError({
      ...error,
      [e.target.name]: "",
    });
  };

  const validate = () => {
    const newError = { ...error };

    if (!form.email) {
      newError.email = "Email Wajib Diisi";
    } else if (!isEmail(form.email)) {
      newError.email = "Email Tidak Valid";
    }

    if (!form.password) {
      newError.password = "Password Wajib Diisi";
    }

    return newError;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const findErrors = validate();

    if (Object.values(findErrors).some((err) => err !== "")) {
      setError(findErrors);
    } else {
      try {
        setSubmitting(true);
        await auth.signInWithEmailAndPassword(form.email, form.password);
      } catch (e) {
        const newError = {};

        switch (e.code) {
          case "auth/user-not-found":
            newError.email = "Email Tidak Terdaftar";
            break;
          case "auth/invalid-email":
            newError.email = "Email Tidak Valid";
            break;
          case "auth/wrong-password":
            newError.password = "Password Salah";
            break;
          case "auth/user-disable":
            newError.email = "Pengguna Diblokir";
            break;
          default:
            newError.email = "Terjadi Kesalahan, Silahkan Coba Lagi";
            break;
        }
        setError(newError);
        setSubmitting(false);
      }
    }
  };

  if (loading) {
    return <AppLoading />;
  }

  if (user) {
    const redirectTo =
      location.state && location.state.from && location.state.from.pathname
        ? location.state.from.pathname
        : "/";
    return <Redirect to={redirectTo} />;
  }

  //console.log(form);

  return (
    <>
      <Helmet>
        <title>Login | Nobar Lovepreneur The Movie</title>
      </Helmet>
      <Container maxWidth="xs">
        <Paper className={classes.paper}>
          <div style={{ display: "flex", justifyItems: "center" }}>
            <Logo />
          </div>
          <Typography className={classes.pageTitle}>Selamat Datang,</Typography>
          <Typography className={classes.pageSubtitle}>
            Silahkan login untuk melanjutkan
          </Typography>

          <form onSubmit={handleSubmit} noValidate>
            <TextField
              id="email"
              type="email"
              name="email"
              margin="normal"
              label="Alamat Email"
              fullWidth
              required
              value={form.email}
              onChange={handleChange}
              helperText={error.email}
              error={error.email ? true : false}
              disabled={isSubmitting}
            />
            <TextField
              id="password"
              type="password"
              name="password"
              margin="normal"
              label="Masukan Password"
              fullWidth
              required
              value={form.password}
              onChange={handleChange}
              helperText={error.password}
              error={error.password ? true : false}
              disabled={isSubmitting}
            />

            <Grid container className={classes.buttons}>
              <Grid item xs>
                <Button
                  disabled={isSubmitting}
                  type="submit"
                  color="primary"
                  variant="contained"
                  size="large"
                  fullWidth
                >
                  Login
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
        <Typography className={classes.pageSubtitle}>
        <div>Belum punya tiket? Beli tiket <a target="_blank" href="/daftar">klik disini.</a> </div>
        <div><span><a target="_blank" href="/resend">Kirim ulang password</a> | <a target="_blank" href="https://wa.me/6281770558516?text=halo kak, saya perlu bantuan...">Hubungi CS</a>. </span></div>
        </Typography>
      </Container>
    </>
  );
}

export default Login;
