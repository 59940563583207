import React, { useState } from 'react';
import TabPanel from '../../../components/TabPanel';
import Grid from '@material-ui/core/Grid';
import useStyles from './styles';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import PostAddIcon from '@material-ui/icons/PostAdd';
import Card from '@material-ui/core/Card';
import { firestore } from '../../../components/FirebaseProvider';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import AddBank from './addBank';

export default function RekTujuan({ value, dataKomisi, user_id }) {
    const classes = useStyles();

    const rekTujaunColl = firestore.collection(`user_bank`).where('user_id', '==', user_id)
    const [dataRek, loadDataRek] = useCollectionData(rekTujaunColl, { idField: 'id' })
    // add bank
    const [openAddBank, setOpenAddBank] = useState(false);
    // console.log(dataRek)
    return <TabPanel value={value} index="rek_bank_tujuan">
        {/* <Typography className={classes.pageSubTitle} component="h1" variant="h5">Komisi</Typography> */}
        <Grid container spacing={3}>
            {
                dataKomisi?.length <= 0 &&
                <Typography className={classes.noOrderMsg} variant="h5">Belum Ada Pencairan</Typography>
            }
            {
                dataRek?.map?.((rek) => {
                    return <Grid className={classes.liquidBlock} key={rek.id} item xs={12} md={6} lg={6}>
                        <Card variant="outlined" className={classes.card} >
                            <div className={classes.liquidSection}>

                                <div className={classes.liquidRow}>
                                    <Typography className={classes.liquidTitle}>Tujuan Bank</Typography>
                                </div>
                                <div className={classes.liquidRow}>
                                    <Typography className={classes.liquidLabel}>Nama Bank</Typography>
                                    <Typography className={classes.liquidValue}>: {rek.name || ''}</Typography>
                                </div>
                                <div className={classes.liquidRow}>
                                    <Typography className={classes.liquidLabel}>No Rek.</Typography>
                                    <Typography className={classes.liquidValue}>: {rek.no_rek || ''}</Typography>

                                </div>
                                <div className={classes.liquidRow}>
                                    <Typography className={classes.liquidLabel}>Atas Nama</Typography>
                                    <Typography className={classes.liquidValue}>: {rek.nama_rek || ''}</Typography>
                                </div>

                                {/* <div className={classes.liquidRow}>
                                    <Typography className={classes.liquidLabel}>Mitra Id</Typography>
                                    <Typography className={classes.liquidValue}>: {rek.mitra_id || ''}</Typography>
                                </div> */}

                            </div>

                        </Card>
                    </Grid>

                })
            }

        </Grid>
        <div className={classes.fab1}>
            <Fab
                variant="extended"
                color="primary"
                onClick={() => {
                    setOpenAddBank(true);
                }}
                tooltip
            >
                <PostAddIcon />+ Data Rekening
            </Fab>
            <AddBank
                open={openAddBank}
                handleClose={() => {
                    setOpenAddBank(false)
                }}
            />
        </div>
    </TabPanel>
}