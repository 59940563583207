import { makeStyles } from '@material-ui/styles';


const useStyles = makeStyles(theme => ({
    fabSiswa: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(2)
    },
    tableStyles: {
        paddingBottom: theme.spacing(6)
    },
    paper: {
        padding: theme.spacing(4),
    },
    title: {
        marginBottom: theme.spacing(2),
    },
    actionButtons: {
        display: 'flex',
        paddingTop: theme.spacing(2),

        marginBottom: theme.spacing(4)
    },
    card: {
        padding: theme.spacing(4),
        marginTop: theme.spacing(2)
    },
    fab1: {
        display: 'flex',
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(6)
    },
    fab2: {
        display: 'flex',
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(16)
    },
    jp1: {
        border: '2px solid #cd3478',
        borderRadius: '5px',
        padding: '13px',
        cursor: 'pointer',
        marginTop: 20,
    },
    liquidSection: {
        marginBottom: 25,
    },
    liquidTitle: {
        color: theme.palette.textColor.lessTextColor,
        borderBottom: 'solid 1px ' + theme.palette.textColor.gray2,
        width: '100%',
        paddingBottom: 10,
        marginBottom: 10,
    },
    liquidRow: {
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'flex-start',
        width: '100%'
    },
    liquidLabel: {
        color: theme.palette.textColor.lessTextColor,
        width: 140,
        marginRight: 10,
        fontSize: 14,
    },
    liquidValue: {
        color: theme.palette.textColor.primeTextColor,
        fontSize: 13,
        width: '75%',
        wordBreak: 'break-all'
    },


}))

export default useStyles;