import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Paper, Typography, Grid, Card } from "@material-ui/core";
import useStyles from "./styles";
import {
  firestore,
  FieldValue,
  functions,
  useFirebase,
} from "../../../components/FirebaseProvider";
import AppPageLoading from "../../../components/AppPageLoading";
import Logo from "../../../components/Logo";
import StoreIcon from "@material-ui/icons/Store";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import StudentsIcon from "@material-ui/icons/People";
import VideoIcon from "@material-ui/icons/Movie";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import VideoPlayer from "./VideoPlayer";
import Produk from "./produk";
import { useDocument } from "react-firebase-hooks/firestore";

function Penulis() {
  const classes = useStyles();
  const { user } = useFirebase();
  const [aksesProduk, setAksesproduk] = useState([]);

  const [player, setPlayer] = useState([]);

  const aksesDoc = firestore.doc(`akses_produk/${user.uid}`);
  const [snapshotAksesDoc, aksesLoading] = useDocument(aksesDoc);
  

  return (
    <>
      <Helmet>
        <title>Seminar Menulis Cerita Fiksi & Skenario Film</title>
      </Helmet>
      <Grid>
          <Paper className={classes.paper}>
            <div style={{ display: "flex", justifyItems: "center" }}>
              <Logo />
            </div>    
          </Paper>
              <Produk key={'31232'} email={user.email} />
      </Grid>
    </>
  );
}

export default Penulis;
