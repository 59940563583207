import { createMuiTheme } from "@material-ui/core/styles";
import deepOrange from "@material-ui/core/colors/deepOrange";

const theme = createMuiTheme({
  direction: "ltr",

  palette: {
    primary: {
      light: "#d24886",
      lighter: "#faebf2",
      main: "#cd3478",
      dark: "#b92f6c",
      contrastText: "#fff",
    },
    secondary: {
      light: "#f0a13b",
      lighter: "#fdf5e9",
      main: "#ee9625",
      dark: "#d68721",
      contrastText: "#fff",
    },
    textColor: {
      white: "#fff",
      gray: "#777",
      gray2: "#ddd",
      gray3: "#eee",
      gray4: "#f2f4f4",
      gray5: "#0000008a",
      gray6: "#aaaaaa",
      gray7: "#bbbbbb",
      gray8: "#7d7d7d",
      gray9: "#ccc",
      green: "#19b821",
      blue: "#2757ca",
      blue2: "#32bdea",
      green2: "#1CBA34",
      lessTextColor: "#848484",
      primeTextColor: "#4F4F4F",
    },
    success: {
      main: "#00ff00",
    },
    info: {
      main: "#30bcec",
    },
    warning: {
      main: "#fdb31b",
    },
    danger: {
      main: "#ff0000",
    },
  },

  typography: {
    useNextVariants: true,
  },
});

export default theme;
