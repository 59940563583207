let firebaseConfig = {
  apiKey: "AIzaSyAUByJgJ_L3X8IJmNqLH_2UwRSDAwU2Tdk",
  authDomain: "lovepreneur-tayang.firebaseapp.com",
  projectId: "lovepreneur-tayang",
  storageBucket: "lovepreneur-tayang.appspot.com",
  messagingSenderId: "277409586308",
  appId: "1:277409586308:web:7e12dc8c063a0b113936d1",
  measurementId: "G-88T8WDFK2P",
};
if (process.env.REACT_APP_ENV === "PROD") {
  firebaseConfig = {
    apiKey: "AIzaSyAfHzGAJKmWlaLjIpks3pGGpW60k-EehX4",
  authDomain: "lovepreneur-nobar.firebaseapp.com",
  projectId: "lovepreneur-nobar",
  storageBucket: "lovepreneur-nobar.appspot.com",
  messagingSenderId: "848197458832",
  appId: "1:848197458832:web:e7c8521705faa22c973115",
  measurementId: "G-X24WWSS45K"
  };
}

export default firebaseConfig;