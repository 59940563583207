import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Login from "./pages/login";
import NotFound from "./pages/404";
import PrivateRoute from "./components/PrivateRoute";
import Private from "./pages/private";
import FirebaseProvider from "./components/FirebaseProvider";

import CssBaseline from "@material-ui/core/CssBaseline";

import ThemeProvider from "@material-ui/styles/ThemeProvider";
import theme from "./config/theme";

import { SnackbarProvider } from "notistack";
import Daftar from "./pages/daftar";
import Resend from "./pages/resend"
import Afiliasi from "./pages/private/afiliasi";

function App() {
  return (
    <>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <SnackbarProvider maxSnack={3} autoHideDuration={3000}>
          <FirebaseProvider>
            <Router>
              <Switch>
                <PrivateRoute exact path="/" component={Private} />
                <PrivateRoute path="/nobar" component={Private} />
                {/* <PrivateRoute path="/courses" component={Private} />
                <PrivateRoute path="/enrolment" component={Private} />
                <PrivateRoute path="/students" component={Private} />
                <PrivateRoute path="/categories" component={Private} /> */}
                <PrivateRoute path="/afiliasi" component={Private} />
                <PrivateRoute path="/gathering" component={Private} />
                <PrivateRoute path="/seminar" component={Private} />
                <PrivateRoute path="/penulis" component={Private} />
                <Route path="/login" component={Login} />
                <Route path="/resend" component={Resend} />
                <Route path="/daftar/:slug?" component={Daftar} />
                <Route component={NotFound} />
              </Switch>
            </Router>
          </FirebaseProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </>
  );
}

export default App;
