import { makeStyles } from "@material-ui/styles";
import check from "../../images/check.png";

const useStyles = makeStyles((theme) => ({
  title: {
    textAlign: "center",
    marginBottom: theme.spacing(3),
  },
  paper: {
    marginTop: theme.spacing(4),
  },
  paperInner:{
    display: 'flex',
    justifyContent: 'space-between',
    flexFlow: 'row wrap',
    "@media(max-width: 767px)": {
      width: "100%",
    },
  },
  paperLeft:{
    width: '62.5%',
    padding: '2em',
    "@media(max-width: 767px)": {
      width: "100%",
    },
  },
  paperTop:{
    display: 'flex',
    justifyContent: 'space-around',
  },
  secured:{
    "@media(max-width: 767px)": {
      width: "47.5%",
    },
  },
  paperRight:{
    width: '37.5%',
    padding: '2em',
    backgroundColor: '#f5f5f5',
    '& > h3':{
      margin: 0,
    },
    "@media(max-width: 767px)": {
      width: "100%",
    },
  },
  benefitList:{
    
    '& > ul li':{
      position: 'relative',
      paddingLeft: 15,
      marginBottom: 5,
      display: 'block'
    },
    '& > ul li::before':{
      background: 'url('+check+')',
      content: '""',
      width: 16,
      height: 13,
      display: 'block',
      position: 'absolute',
      top: 3,
      left: -10,
    },
  },
  orderDetail:{
    backgroundColor: '#fff',
    padding: 20,
    border: 'solid 1px #cd3478',
    '& > h5':{
      margin: '0 0 5px',
    }
  },
  order:{
    display: 'flex',
    flexDirection: 'row',
  },
  productName:{
    width: '65%',
  },
  uniqueCode:{
    display: 'flex',
    flexDirection: 'row',
    fontWeight: 'bold',
    marginTop: 15,
    borderBottom: 'solid 1px #ddd',
    paddingBottom: 10,
    marginBottom: 10,
  },
  TotalPrice:{
    display: 'flex',
    flexDirection: 'row',
    fontWeight: 'bold',
  },
  TotalPriceLabel:{
    width: '65%',
  },
  buttons: {
    marginTop: theme.spacing(6),
  },
  forgotPassword: {
    marginTop: theme.spacing(3),
  },
  pageTitle: {
    marginTop: 20,
    color: "grey",
    fontSize: 20,
    textAlign: "center",
    fontWeight: 600,
    marginBottom: 0,
  },

  pageSubtitle: {
    color: "grey",
    textAlign: "center",
    fontSize: 13,
    fontWeight: 400,
    marginTop: 0,
    marginBottom: 30,
  },
  testimoniesBox:{
    padding: '2em',
    backgroundColor: "#ffc3e5",
    display: 'flex',
    justifyContent: 'space-between',
    flexFlow: 'row wrap',
  },
  testimony:{
    width: '47.5%',
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'flex-start',
    "@media(max-width: 767px)": {
      width: "100%",
      marginBottom: 20,
    },
  },
  avatar:{
    width: 'auto',
    height:  100,
    marginRight: 10,
    border: 'solid 1px #fff'
  },
  avatarName:{
    margin: 0,
  }
}));

export default useStyles;
