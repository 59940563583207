import React, { useState } from 'react';
import TabPanel from '../../../components/TabPanel';
import Grid from '@material-ui/core/Grid';
import useStyles from './styles';
import Typography from '@material-ui/core/Typography';
import PostAddIcon from '@material-ui/icons/PostAdd';
import Card from '@material-ui/core/Card';
import { currency } from '../../../utils/formatter';
import { unixToDate } from '../../../utils/datetimes';
import Fab from '@material-ui/core/Fab';

import AddPencairanRekber from './add';


export default function ListPencairan({ value, dataKomisi, responOmset, responOmsetGroup, minPencairan }) {
    const classes = useStyles();
    // add pencairan
    const [openAddProduct, setOpenAddProduct] = useState(false);




    // console.log(formKomisi)
    return <TabPanel value={value} index="pencairan">
        {/* <Typography className={classes.pageSubTitle} component="h1" variant="h5">Komisi</Typography> */}
        <Grid container spacing={3}>
            {
                dataKomisi?.length <= 0 &&
                <Typography className={classes.noOrderMsg} variant="h5">Belum Ada Pencairan</Typography>
            }
            {
                dataKomisi?.map?.((komisi) => {
                    return <Grid className={classes.liquidBlock} key={komisi.id} item xs={12} md={6} lg={6}>
                        <Card variant="outlined" className={classes.card} >
                            <div className={classes.liquidSection}>

                                <div className={classes.liquidRow}>
                                    <Typography className={classes.liquidTitle}>Pencairan Komisi</Typography>
                                    
                                </div>
                                <div className={classes.liquidRow}>
                                    <Typography className={classes.liquidLabel}>ID </Typography>
                                    <Typography className={classes.liquidValue}>: {komisi?.id}</Typography>
                                </div>
                                <div className={classes.liquidRow}>
                                    <Typography className={classes.liquidLabel}>Tanggal</Typography>
                                    <Typography className={classes.liquidValue}>: {unixToDate(komisi?.created_at?.toMillis()) || ''}</Typography>
                                </div>
                                <div className={classes.liquidRow}>
                                    <Typography className={classes.liquidLabel}>Bank</Typography>
                                    <Typography className={classes.liquidValue}>: {komisi?.bank_tujuan?.name || ''}</Typography>
                                </div>
                                <div className={classes.liquidRow}>
                                    <Typography className={classes.liquidLabel}>No Rek.</Typography>
                                    <Typography className={classes.liquidValue}>: {komisi?.bank_tujuan?.no_rek || ''}</Typography>
                                </div>
                                <div className={classes.liquidRow}>
                                    <Typography className={classes.liquidLabel}>Atas Nama</Typography>
                                    <Typography className={classes.liquidValue}>: {komisi?.bank_tujuan?.nama_rek || ''}</Typography>
                                </div>
                                {/* <div className={classes.liquidRow}>
                                                    <Typography className={classes.liquidLabel}>No Rek.</Typography>
                                                    <Typography className={classes.liquidValue}>: {komisi.tujuan_bank.no_rek || ''}</Typography>
                                                </div> */}
                                <div className={classes.liquidRow}>
                                    <Typography className={classes.liquidLabel}>Total</Typography>
                                    <Typography className={classes.liquidValue}>: {currency(komisi?.amount) || ''}</Typography>
                                </div>
                                <div className={classes.liquidRow}>
                                    <Typography className={classes.liquidLabel}>Biaya</Typography>
                                    <Typography className={classes.liquidValue}>: {currency(komisi?.payout_fee) || ''}</Typography>
                                </div>
                                <div className={classes.liquidRow}>
                                    <Typography className={classes.liquidLabel}>Diterima</Typography>
                                    <Typography className={classes.liquidValue}>: {currency(komisi?.amount - komisi?.payout_fee) || ''}</Typography>
                                </div>
                                {/* <div className={classes.liquidRow}>
                                    <Typography className={classes.liquidLabel}>Mitra Id</Typography>
                                    <Typography className={classes.liquidValue}>: {komisi.mitra_id || ''}</Typography>
                                </div> */}
                                <div className={classes.liquidRow}>
                                    <Typography className={classes.liquidLabel}>Status</Typography>
                                    <Typography className={classes.liquidValue}>: {komisi?.status || ''}</Typography>
                                </div>
                                <div className={classes.liquidRow}>
                                    {komisi?.status === 'failed' ?
                                                         <div className={classes.liquidRow}>
                                                            <Typography className={classes.liquidLabel}>Failure Code</Typography>
                                                            <Typography className={classes.liquidValue}>: {komisi?.failure_code || ''}</Typography>
                                    </div> : "" }
                                </div>
                            </div>
                            {/* <div>
                                            <Button className={classes.btnWrap3} variant="contained" color="primary"
                                                // userId={users.id}
                                                onClick={(e) => {
                                                    // setOpenDialog({
                                                    //     open: true,
                                                    //     id: komisi.user_id,
                                                    // });
                                                }}>Cek Kredit</Button>
                                        </div> */}
                            <div className={classes.liquidRow}>
                                {/* {komisi.status === 'approved' ? <>
                                                    <Grid>
                                                        <InputLabel className={classes.liquidTitle}>Upload Bukti Transfer</InputLabel>
                                                        <div className={classes.uploadIconPencairan}>

                                                            {komisi?.bukti_transfer_permbayaran &&
                                                                <div className={classes.imgWrap}><img src={komisi?.bukti_transfer_permbayaran} className={classes.imgPreview} alt="" /></div>}
                                                            {!komisi?.bukti_transfer_permbayaran &&
                                                                <ImageIcon
                                                                    className={classes.previewIconPencairan}
                                                                    size="large"
                                                                    color="disabled"
                                                                />}

                                                            <input
                                                                className={classes.hideInputFile}
                                                                type="file"
                                                                name="bukti_transfer_permbayaran"
                                                                id={"upload-icon-pencairan-" + komisi.id}
                                                                accept="image/jpeg,image/png,image/jpg"
                                                                onChange={handleUploadIcon(komisi)}
                                                            />
                                                            <label htmlFor={"upload-icon-pencairan-" + komisi.id}>
                                                                <Button
                                                                    disabled={isSubmitting}
                                                                    name="bukti_transfer_permbayaran"

                                                                    variant="outlined"
                                                                    component="span"
                                                                >Upload<UploadIcon className={classes.iconRight} /></Button>
                                                            </label>

                                                            {error.bukti_transfer_permbayaran &&
                                                                <Typography color="error">
                                                                    {error.bukti_transfer_permbayaran}
                                                                </Typography>}

                                                        </div>
                                                    </Grid>
                                                </> : null
                                                } */}
                            </div>
                        </Card>
                    </Grid>

                })
            }

        </Grid>
        <div className={classes.fab1}>

            <Fab
                color="secondary"
                onClick={() => {
                    setOpenAddProduct(true);
                }}
                tooltip
            >
                <PostAddIcon />
            </Fab>
            <AddPencairanRekber
                totalOmset={responOmset.total_komisi + responOmsetGroup.total_komisi_group}
                open={openAddProduct}
                handleClose={() => {
                    setOpenAddProduct(false);
                }}
                totalPencairan={responOmset.total_pencairan_processing + responOmset.total_pencairan_success}
                minPencairan={minPencairan}
            />
        </div>
    </TabPanel>
}