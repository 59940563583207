import React, { useState } from 'react';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { firestore, useFirebase } from './FirebaseProvider';
import AppLoading from './AppLoading';

const DataContext = React.createContext();


export function useData() {
    return React.useContext(DataContext);
}

export default function DataProvider(props) {

    const { user } = useFirebase();

    const [user_id] = useState(user?.uid);
    const queryKategori = firestore.collection('kategori').orderBy('created_at', 'desc');
    const [kategoriList, loadingKategoriList] = useCollectionData(queryKategori, { idField: "id" });

    const queryKursus = firestore.collection('kursus').orderBy('created_at', 'desc');
    const [kursusList, loadingKursusList] = useCollectionData(queryKursus, { idField: "id" });

    const queryUser = firestore.collection('user').orderBy('nama', 'desc');
    const [userList, loadingUserList] = useCollectionData(queryUser, { idField: "id" });

    const queryPencairan = firestore.collection('pencairan').where('user_id', '==', user_id);
    const [pencairanList, loadingPencairan] = useCollectionData(queryPencairan, { idField: "id"});

    if (loadingKategoriList || loadingKursusList || loadingUserList || loadingPencairan) {
        return <AppLoading />
    }
    return (
        <DataContext.Provider
            value={{
                kategoriList,
                kursusList,
                userList,
                user_id,
                pencairanList
            }}
        >
            {props.children}
        </DataContext.Provider>
    );
}
