import React, { useState, useEffect } from "react";
import useStyles from "./styles";
import {
  firestore,
  FieldValue,
  functions,
  useFirebase,
} from "../../../components/FirebaseProvider";
import { Paper, Typography, Grid, Card } from "@material-ui/core";
import AppPageLoading from "../../../components/AppPageLoading";
import Button from "@material-ui/core/Button";
import VideoPlayer from "./VideoPlayer";

function Video(props) {
    const classes = useStyles();
    const [player, setPlayer] = useState({});

    async function loadVideo(id) {
      setPlayer({          
        currentVideo: id,
        loading: true,
        otp: "",
        playbackInfo: ""
      });
      
      const getOTP = functions.httpsCallable("video-getOTP");
      const res = await getOTP({ nama: props.email , id});

    setPlayer({          
        currentVideo: id,
        loading: false,
        otp: res.data?.otp,
        playbackInfo: res.data?.playbackInfo
      })
    
      
    //set expiry date since klik button 'mulai nobar'
    const setExpiryDate = functions.httpsCallable("video-setExpiry");
    await setExpiryDate({ uid: props.uid , aksesproduk: props.id });
    }

    return (<>
    <Grid container className={classes.buttons}>
                <Grid item xs>
                  <Button
                    onClick={() => loadVideo(props.videoid)}
                    type="submit"
                    color="primary"
                    variant="contained"
                    size="large"
                    fullWidth
                  >
                    Klik Untuk Mulai Nonton {props.title}
                  </Button>
                  
                </Grid>
              </Grid>
              {player?.loading && <AppPageLoading />}
              {!player?.loading && player?.otp && player?.playbackInfo && (
                <VideoPlayer
                  otp={player.otp}
                  playbackInfo={player.playbackInfo}
                />
              )}
    </>)

}

export default Video;
