import { makeStyles } from '@material-ui/styles';


const useStyles = makeStyles(theme => ({
    fabSiswa: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(2)
    },
    tableStyles: {
        paddingBottom: theme.spacing(6)
    },
    paper: {
        padding: theme.spacing(4),
    },
    title: {
        marginBottom: theme.spacing(2),
    },
    actionButtons: {
        display: 'flex',
        paddingTop: theme.spacing(2),

        marginBottom: theme.spacing(4)
    },
    card: {
        padding: theme.spacing(4),
        marginTop: theme.spacing(2)
    },


}))

export default useStyles;