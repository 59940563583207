import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Paper, Typography, Grid, Card } from "@material-ui/core";
import useStyles from "./styles";
import {
  firestore,
  FieldValue,
  functions,
  useFirebase,
} from "../../../components/FirebaseProvider";
import AppPageLoading from "../../../components/AppPageLoading";
import Logo from "../../../components/Logo";
import StoreIcon from "@material-ui/icons/Store";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import StudentsIcon from "@material-ui/icons/People";
import VideoIcon from "@material-ui/icons/Movie";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import VideoPlayer from "./VideoPlayer";
import { useDocument } from "react-firebase-hooks/firestore";

function Produk(props) {
  const classes = useStyles();
  const { user } = useFirebase();
//   const [aksesProduk, setAksesproduk] = useState([]);

  const [player, setPlayer] = useState([]);

//   const aksesDoc = firestore.doc(`akses_produk/${user.uid}`);
//   const [snapshotAksesDoc, aksesLoading] = useDocument(aksesDoc);

//   useEffect(() => {
//     if (snapshotAksesDoc) {
      
//         let prodArray = [];
//         //let playerArray = [];
        
//         for(let i=0;i<snapshotAksesDoc.data()?.akses_produk.length;i++) {
//           prodArray.push({
//             ...snapshotAksesDoc.data()?.akses_produk[i], 
//             isExpired: new Date(snapshotAksesDoc.data()?.akses_produk[i]?.expiryDate?.seconds * 1000) < new Date() ? true : false,
//             isLaunched: new Date(snapshotAksesDoc.data()?.akses_produk[i]?.launchdate?.seconds * 1000) < new Date() ? true : false
//           })
//           setAksesproduk(prodArray);
          

//           // playerArray.push({
//           //   currentVideo: null,
//           //   loading: false,
//           //   otp: "",
//           //   playbackInfo: "",
//           // })
//           // setPlayer(playerArray);
//         }
      
//     }
//   }, [snapshotAksesDoc]);
  

  async function loadVideo(id , identifier) {

    //check if player already exist for this video
    let myplayer = player.find( mov => mov.currentVideo === id);
    //if exist, update the state
    if (myplayer) {
        console.log('not empty...')
      const newArray = player.map((x, index) => x.currentVideo === id ? {...x, loading: true, currentVideo: id} : x);
      setPlayer(newArray);
    }
    //if not exist, add new array for the state
    else {
        console.log('empty...')
      setPlayer([{          
        currentVideo: id,
        loading: true,
        otp: "",
        playbackInfo: ""
      }]);

      }
      
      const getOTP = functions.httpsCallable("video-getOTP");
      const res = await getOTP({ nama: props.email , id: '9ab5ce32ef3c4bb2b09cb8ca112f3ca8'});
    //   console.log(player);
    //   const newArray = player.map((x, index) => {
    //       return x.currentVideo === id ? {...x, otp: res.data?.otp, playbackInfo: res.data?.playbackInfo, loading: false } : x;
    //   })
        //   const newArray1 = player.map((x, index) => {
        //   return x.currentVideo === id ? {...x, otp: "123", playbackInfo: "321", loading: false } : x;

    //   })
    // belum valid untuk produk yg terdiri dari multiple video
    setPlayer([{          
        currentVideo: id,
        loading: false,
        otp: res.data?.otp,
        playbackInfo: res.data?.playbackInfo
      }])
    
    }
  const ind = 0;

  return (
    <>
      
      <Grid>
        <h3>Seminar Singlelillah bersama Kang Abay dan Arif Rahman Lubis</h3>
      <Button
                    onClick={() => loadVideo(props.id, ind)}
                    type="submit"
                    color="primary"
                    variant="contained"
                    size="large"
                    fullWidth
                  >
                    Klik Untuk Mulai Nonton Seminar Online
                  </Button>

                  {player[ind]?.loading && <AppPageLoading />}
              {!player[ind]?.loading && player[ind]?.otp && player[ind]?.playbackInfo && (
                <VideoPlayer
                  otp={player[ind].otp}
                  playbackInfo={player[ind].playbackInfo}
                />
              )}

        
      </Grid>
    </>
  );
}

export default Produk;
